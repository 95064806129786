import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DEFAULT_SIC } from '../../constants/default-sic.const';

@Injectable({
  providedIn: 'root',
})
export class SicService {
  private selectedSicSubject = new BehaviorSubject<string>(DEFAULT_SIC);
  selectedSic$: Observable<string> = this.selectedSicSubject.asObservable();
  get selectedSic(): string {
    return this.selectedSicSubject.value;
  }
  constructor() {}

  setSelectedSic(sicCd: string): void {
    if (sicCd !== this.selectedSic) {
      this.selectedSicSubject.next(sicCd);
    }
  }
}

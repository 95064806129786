import { Component, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';

import { NotificationMessage, NotificationService, NotificationTypeEnum } from '@xpo-ltl/data-api';
import { XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SnackbarStatus } from './shared/enums/snackbar-status.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit, OnDestroy {
  message: string;
  show: boolean;

  @Input()
  showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  private snackBarRef: MatSnackBarRef<XpoNotificationTemplate>;

  constructor(private notificationService: NotificationService, private zone: NgZone, private snackbar: XpoSnackBar) {}

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.message = message.text;
              this.show = message.type === NotificationTypeEnum.ShowOverlay;
            } else {
              this.snackBarRef = this.snackbar.open({
                message: message.text,
                status: SnackbarStatus[message.snackbarConfig.status],
                action:
                  message.snackbarConfig != null &&
                  message.snackbarConfig.actionHandler != null &&
                  message.snackbarConfig.actionHandler.actionLabel() != null
                    ? message.snackbarConfig.actionHandler.actionLabel()
                    : null,
                matConfig: {
                  duration: message.snackbarConfig.durationInMillis,
                },
              });

              if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
                if (message.snackbarConfig.actionHandler.onAction != null) {
                  this.snackBarRef.onAction().subscribe(message.snackbarConfig.actionHandler.onAction);
                }
                if (message.snackbarConfig.actionHandler.afterOpened != null) {
                  this.snackBarRef.afterOpened().subscribe(message.snackbarConfig.actionHandler.afterOpened);
                }
                if (message.snackbarConfig.actionHandler.afterDismissed != null) {
                  this.snackBarRef.afterDismissed().subscribe(message.snackbarConfig.actionHandler.afterDismissed);
                }
              }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

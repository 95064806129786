import { NgModule } from '@angular/core';

import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatRadioModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
} from '@angular/material';

@NgModule({
  imports: [],
  exports: [
    XpoCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    MatAutocompleteModule,
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class MaterialModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
// import { RoleSwitchGuard } from './shared/guards/role-switch/role-switch-guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    component: LandingPageComponent,
    // FOR SSO
    canActivate: [XpoAuthenticationGuard],
    // canActivate: [XpoAuthenticationGuard, RoleSwitchGuard],
  },
  {
    path: AppRoutes.EQUIPMENT_PAGE,
    loadChildren: () => import('./equipment/equipment.module').then((m) => m.EquipmentModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard],
    // canActivate: [XpoAuthenticationGuard, RoleSwitchGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.LANDING_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

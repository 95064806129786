import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoFeedback, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { XpoLtlAppSwitcherService, XpoLtlFeedbackService, XpoLtlReleaseNotesService } from '@xpo/ngx-ltl';

import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval, Observable } from 'rxjs';
import { delay, retryWhen, skipWhile, take } from 'rxjs/operators';

import { DEFAULT_SIC } from './shared/constants/default-sic.const';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';
import { SicService } from './shared/services/sic-service/sic-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  defaultSic = DEFAULT_SIC;
  accountPopoverConfig: XpoAccountPopoverConfig;
  isUserLogged = false;

  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private authService: XpoLtlAuthenticationService,
    private sicService: SicService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      this.routes = [
        {
          label: 'Main',
          path: `/${AppRoutes.LANDING_PAGE}`,
        },
        {
          label: 'Equipments',
          path: `/${AppRoutes.EQUIPMENT_PAGE}`,
        },
      ];

      /** Shell setup */
      this.title = 'Dock Operations';
      this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
      /** Initialize SIC switcher by getting logged in user's sic */
      this.handleLoggedInUser();
      this.loginService.userLoggedIn$.subscribe(() => {
        this.handleLoggedInUser();
      });
    });
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  handleSicChanged(newSic: string): void {
    this.sicService.setSelectedSic(newSic);
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.isUserLogged = true;
            this.populateAccountPopover(user);
            this.populateAppSwitcher();
            this.initializeDynatrace(user);
          } else {
            this.isUserLogged = false;
          }
        },
        (error) => {
          this.isUserLogged = false;
          console.log('ERROR', error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { TotalTextCellRendererComponent } from './components/cell-renderer-components/cell-renderer-components/text-cell-render.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    TotalTextCellRendererComponent,
    // Pipes/Directives
  ],
  imports: [
    // Pipes/Directives
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    // Pipes/Directives
  ],
  entryComponents: [TotalTextCellRendererComponent],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
